<template>
  <div class="product-wrap programme-fire">
    <Header is-active="programme" theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="sub-banner banner-fire"></div>
    <div class="white-wrap">
      <div class="max-center">
        <div class="txt-box align-ct no-indent pd-5">
          <p>智慧消防是一种先进的解决方案，与传统消防相比，注重打通各系统间的信息孤岛、提升感知预警能力和应急指挥智慧能力。 </p>
          <p>通过更早发现、更快处理，将火灾风险和影响降到最低。真正意义上的智慧消防绝不仅仅是消防设备数据联网到平台，</p>
          <p>通过运用物联网、云计算、AI、区块链等高新技术，实现环境感知、行为管理、流程把控、智能研判、科学指挥等目标。</p>
        </div>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center">
        <div class="fz-big color-orange align-ct"><h4>行业痛点 </h4></div>
        <div class="pain-box mt-2">
          <div class="td-item">
            <img src="../../assets/img/programme/fire/td_a.jpg" alt="安全意识薄弱">
            <p class="color-59">安全意识薄弱</p>
          </div>
          <div class="td-item">
            <img src="../../assets/img/programme/fire/td_b.jpg" alt="应对措施僵化">
            <p class="color-59">应对措施僵化</p>
          </div>
          <div class="td-item">
            <img src="../../assets/img/programme/fire/td_c.jpg" alt="技术手段落后">
            <p class="color-59">技术手段落后</p>
          </div>
          <div class="td-item">
            <img src="../../assets/img/programme/fire/td_d.jpg" alt="缺乏有效监管">
            <p class="color-59">缺乏有效监管</p>
          </div>
        </div>
      </div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <div class="fz-big color-orange align-ct"><h4>云昇方案</h4></div>
        <div class="txt-box">
          建设智慧消防物联网管理平台，在消防安全重点单位消控室安装用户信息传输装置，根据需要安装并接入消防物联网火灾报警监控、消防水系统监控、电气火灾监控、消控室视频监控、消防电源监控、消防设施RFID管理、消防设施巡查管理等多个子系统，实现消防物联网监控管理中心对联网重点单位建筑消防设施的远程监控和实时管理。掌握重点单位建筑消防设施底数及运行情况;对监测数据进行智能分析和实时统计;实现对问题突出的单位法人、消防安全责任人进行点对点的信息警示和提示，落实单位主体责任，及时消除火灾隐患。
        </div>
        <div class="auto-img mt-2">
          <img src="../../assets/img/product/fire/pic_2.png" alt="云昇方案">
        </div>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center">
        <div class="fz-big color-orange align-ct"><h4>核心功能</h4></div>
        <div class="customized-box mt-5">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont color-orange icon-monitor"></i></div>
            <p>实时监控</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont color-orange icon-baobiao"></i></div>
            <p>数据分析</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont color-orange icon-lishijilu"></i></div>
            <p>数据分析</p>
          </div>
        </div>
        <div class="customized-box mt-5">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont color-orange icon-baojing"></i></div>
            <p>预警处理</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont color-orange icon-GIS"></i></div>
            <p>GIS地图</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont color-orange icon-app"></i></div>
            <p>APP联动</p>
          </div>
        </div>
        <div class="customized-box mt-5">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont color-orange icon-xiaoxits"></i></div>
            <p>消息推送</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont color-orange icon-quanxian"></i></div>
            <p>权限管理</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont color-orange icon-yckz"></i></div>
            <p>远程控制</p>
          </div>
        </div>
      </div>
    </div>
    <div class="white-wrap">
      <div class="max-center">
        <div class="fz-big color-orange align-ct"><h4>方案优势</h4></div>

        <div class="fire-txt-box">
          <el-row :gutter="30">
            <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
              <div class="txt-item">
                <h6 class="color-orange">兼容性广</h6>
                <div class="txt-info">
                  兼容并入各类消防子系统，全方位精确监控消防安全设备运行情况和重大危险源。
                  <br>
                  <br>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
              <div class="txt-item">
                <h6 class="color-orange">数据整合</h6>
                <div class="txt-info">
                  将既有数据，直接整合进来,智能研判、分析，为相关部门提供实时、精准、全面的消防大数据，提升应急处理决策。
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
              <div class="txt-item">
                <h6 class="color-orange">施工简单</h6>
                <div class="txt-info">
                  采用无线通讯技术，在项目施工中无需布线、穿墙，能够快速、低成本地完成监控网络的建设，避免电气设施的受损。
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
              <div class="txt-item">
                <h6 class="color-orange">易扩展</h6>
                <div class="txt-info">
                  可随意增减数据采集节点，快速实现系统扩容；实现远程维护；可根据需求更换传感器，实现系统功能的扩展。
                </div>
              </div>
            </el-col>
          </el-row>
        </div>

      </div>
    </div>
    <Footer></Footer>
    <BackTop></BackTop>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import BackTop from '../../components/BackTop'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '智慧农业-方案中心,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控智慧农业-方案中心,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  components: {
    Header,
    Footer,
    BackTop
  },
  data () {
    return {}
  },
  methods: {}
}
</script>
<style lang="less" scoped>
@import "../../../public/css/product.less";

.programme-fire {
  .banner-fire {
    background: url("../../assets/img/programme/fire/banner.jpg") no-repeat center;
    background-size: cover;
    position: relative;
  }

  .fire-txt-box {
    width: 100%;
    height: auto;
    padding-bottom: 15px;

    .txt-item {
      padding: 5%;
      background: #FAFAFA;
      box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.16);
      text-align: left;
      margin-top: 5%;
      box-sizing: border-box;

      h6 {
        padding-bottom: 5%;
        font-size: 24px;
      }
    }
  }
}

</style>
